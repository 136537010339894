<template>
    <div class="main-container">
        <!-- SECTION TITLE -->
        <section class="section-title">
            <div class="title-container">
                <div class="col-img">
                    <img src="./../assets/banner-visitors.png" alt="banner visitors" />
                </div>
                <div class="col-title">
                    <h1>Visitors</h1>
                    <p>Come meet key players and thought leaders in the AR & IoT industries, interact with exhibitors, and attend specialized conferences</p>
                     <a href="https://percallnext.my-virtual-convention.com/register" target="_blank" class="actions-btn sponsors-btn margin-btn">Free Registration</a>
                </div>
            </div>
        </section>
        <!-- SECTION WHY COME -->
        <section class="section-why">
            <div class="why-sep-top"></div>
            <h2>Why Become an Attendee?</h2>
            <div class="list-why-container">
                <ul class="list-why">
                    <li>Discover the key players in the AR & IoT industries and interact with exhibitors</li>
                    <li>Follow the market and select your future partners, customers and suppliers</li>
                    <li>Discover all the products and services offered by the exhibitors in their 3D booths</li>
                    <li>Chat with exhibitors and attendees via the live chat</li>
                    <li>Set up sales appointments</li>
                    <li>Attend roundtables with industry leaders live or in replay</li>
                    <li>Watch live product demos from exhibitors</li>
                    <li>Search and network with key industry experts, both exhibitors and visitors</li>
                    <li>Stay connected with the markets and professionals around the world</li>
                </ul>
            </div>
            <div class="btn-table">
                <router-link to="/round-tables"  class="actions-btn actions-login">Schedule of Roundtables</router-link>
            </div>
            <div class="why-sep-bottom"></div>
        </section>
        <!-- SECTION WHO -->
        <section class="section-who">
            <h2>What Kinds of Companies are Attending the Conference?</h2>
            <div class="who-container">
                <div class="col-who">
                    <ul>
                        <li>AR/VR Solutions</li>
                        <li>AR/VR Device</li>
                        <li>Artificial Intelligence Experts and Vendors</li>
                        <li>Data Collection / Data Hub</li>
                        <li>IoT Plaform</li>
                        <li>IoT Applications</li>
                        <li>Machine Learning</li>
                        <li>Network / Connectivity</li>
                    </ul>
                </div>
                <div class="col-who">
                    <ul>
                        <li>Robotics</li>
                        <li>Sensors</li>
                        <li>Smart Tools</li>
                        <li>Consulting</li>
                        <li>Expertise</li>
                        <li>Integration</li>
                        <li>Support</li>
                        <li>Training</li>
                    </ul>
                </div>
            </div>
            <div class="actions-container">
                <div class="actions-col">
                    <h3>REGISTER FOR THE EVENT</h3>
                    <a href="https://percallnext.my-virtual-convention.com/register" target="_blank" class="actions-btn">Register</a>
                </div>
                <div class="actions-col">
                    <h3>ALREADY HAVE AN ACCOUNT?</h3>
                    <a href="https://percallnext.my-virtual-convention.com/login" target="_blank" class="actions-btn actions-login">Log in</a>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name : 'Visitors'
}
</script>

<style lang="scss" scoped>
    .section-title {
        margin-top:150px;
        padding:80px 0;
        background-image: url('./../assets/bg-home-participate.png');
        background-position: center right;
        background-repeat: no-repeat;
    }

    .title-container {
        width:$box;
        margin:0 auto;
        max-width: 90%;
        display: flex;
        align-items: center;
    }

    .col-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width:50%;
    }

    .col-img img {
        max-width: 90%;
    }

    .col-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
    }

    .col-title h1 {
        color:$main-color;
        font-size:40px;
        font-weight:700;
        padding: 0;
        margin: 0 0 20px 0;
        text-align: center;
    }

    .col-title p {
        color:$text-color;
        text-align: center;
        font-size:25px;
        line-height: 1.5em;
        max-width: 600px;
        margin: 0;
    }

    .section-why {
        padding:180px 0;
        background-image: url('./../assets/bg-why-exhibit.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
    }

    .why-sep-top {
        position: absolute;
        top:0;
        left:0;
        right:0;
        height:100px;
        background-image: url('./../assets/sep-home-exhibit-top.png');
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .why-sep-bottom {
        position: absolute;
        bottom:0;
        left:0;
        right:0;
        height:100px;
        background-image: url('./../assets/sep-home-exhibit-bottom.png');
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .list-why-container {
        width:$box;
        margin:0 auto;
        max-width: 90%;
        display: flex;
        align-items: center;
    }

    .list-why {
        max-width: 80%;
        margin: 40px auto 0 auto;
        padding:0;
    }

    .list-why li {
        color:$secondary-color;
        font-size:20px;
        margin-bottom:20px;
        list-style: none;
        position: relative;
        left:  40px;
        font-weight:600;
        margin-right: 20px;
    }

    .list-why li::before {
        content:'';
        width:25px;
        height:20px;
        background-image: url('./../assets/puce.png');
        background-position: center;
        background-size: initial;
        background-repeat: no-repeat;
        position: absolute;
        left:-40px;
        top:3px;
    }

    .section-who {
        padding:80px 0;
        background-image: url('./../assets/bg-home-participate.png');
        background-position: center right;
        background-repeat: no-repeat;
    }

    .section-who h2 {
        color:$main-color;
    }

    .who-container {
        width:$box;
        margin:80px auto;
        max-width: 90%;
        display: flex;
    }

    .col-who {
        width:44%;
        margin:0 3%;
    }

    .col-who li {
        color:$main-color;
        font-size:20px;
        margin-bottom:20px;
        list-style: none;
        position: relative;
        left:  40px;
        font-weight:600;
        margin-right: 20px;
    }

    .list-light {
        font-weight:400;
    }

    .col-who li::before {
        content:'';
        width:25px;
        height:22px;
        background-image: url('./../assets/puce-blue.png');
        background-position: center;
        background-size: initial;
        background-repeat: no-repeat;
        position: absolute;
        left:-40px;
        top:3px;
    }

    .btn-table {
        width:100%;
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width:768px) {
        .col-title h1 {
            font-size:30px
        }   
        
        .col-title p {
            font-size:20px;
        }

        .title-container {
            flex-direction: column;
        }

        .col-img, .col-title {
            width:96%;
        }

        .section-title {
            padding:40px 0;
        }

        .list-why li, .col-who li {
            font-size:18px;
            left:20px;
        }

        .section-who {
            padding:40px 0; 
        }

        .who-container {
            flex-direction: column;
        }

        .col-who {
            width:100%;
        }

    }
</style>